@import '../common/colors';
@import '../common/fonts';
@import '../common/sizes';
@import '../components/buttons';

$title-height: 90px;
$access-height: 130px;
$color-bg: $color-deep-green;
$color-fg: $color-white;
$color-grey-separator: #CCC;
$screen-width-logo-sm: 1180px;
$screen-width-menu-stretch: 1080px;

header {
    align-items: center;
    background-color: transparent;
    color: $color-fg;
    display: flex;
    flex-direction: row;
    gap: 80px;
    left: 0;
    padding: 10px 40px;
    position: sticky;
    right: 0;
    top: 0;
    transition: .2s;
    z-index: 1;

    .container {
        gap: 0;
    }

    // TODO: Simplify this
    .ham {
        cursor: pointer;
        display: none;
        height: 50px;
        -webkit-tap-highlight-color: transparent;
        transition: transform 400ms;
        user-select: none;
        width: 34px;

        &.hamRotate.active {
            transform: rotate(45deg);
        }

        &.hamRotate180.active {
            transform: rotate(180deg);
        }

        .line {
            fill: none;
            stroke: $color-white;
            stroke-linecap: round;
            stroke-width: 5.5;
            transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;

            &.top {
                stroke-dasharray: 40 82;
            }

            &.middle {
                stroke-dasharray: 40 111;
            }

            &.bottom {
                stroke-dasharray: 40 161;
            }
        }

        &.ham7.active .line {
            &.top {
                stroke-dasharray: 17 82;
                stroke-dashoffset: -62px;
            }

            &.middle {
                stroke-dashoffset: 23px;
            }

            &.bottom {
                stroke-dashoffset: -83px;
            }
        }
    }

    h1 {
        display: flex;

        .logo svg {
            height: 60px;
            width: 218px;
        }
    }

    nav {
        flex: 1 1 auto;
        height: auto !important;

        .mobile-menu {
            display: none;
        }

        & > ul {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            justify-content: center;

            li {
                transition: background-color .1s ease-out,
                    color .1s ease-out;

                a {
                    display: block;
                    font-size: 14px;
                    font-weight: $font-weight-medium;
                    line-height: 150%;
                    padding: 0 16px;
                    text-decoration: none;
                }

                &.submenu {
                    position: relative;

                    & > a {
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                    }

                    ul, .features-container {
                        background-color: $color-base-white;
                        border-radius: 0 0 8px 8px;
                        box-shadow: 2px 4px 6px 0 transparentize($color-ocean-blue, .55);
                        display: none;
                        flex-direction: column;
                        gap: 14px;
                        left: -42px; // same as left padding
                        padding: 42px;
                        position: absolute;
                        text-align: start;
                        top: calc(100% + 30px);
                        width: max-content;
                        z-index: 1;
                    }

                    .features-container {
                        color: $color-base-gray;
                        font-size: 14px;
                        grid-gap: 45px;
                        grid-template-columns: repeat(2, 1fr);
                        min-width: 610px;

                        .feature {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                        }

                        .title {
                            align-items: center;
                            display: flex;
                            font-weight: $font-weight-bold;
                            gap: 8px;
                            text-transform: uppercase;
                        }

                        .feature-links {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            padding: 0 23px;

                            a {
                                padding: 0;

                                &:hover {
                                    font-weight: $font-weight-bold;
                                }
                            }
                        }
                    }

                    ul {
                        li {
                            color: $color-base-gray;
                            letter-spacing: .14px; // Hack to avoid text shifting on hover

                            &:hover a {
                                font-weight: $font-weight-bold;
                                letter-spacing: 0;
                            }
                        }
                    }

                    &:hover {
                        ul, .features-container {
                            &::before {
                                content: '';
                                display: block;
                                height: 30px;
                                left: 0;
                                position: absolute;
                                top: -30px;
                                width: 100%;
                            }
                        }

                        .features-container {
                            display: grid;
                        }

                        ul {
                            display: flex;
                        }
                    }
                }
            }

            &:hover {
                & > li > a {
                    opacity: .5;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .feature-links {
        a {
            font-weight: $font-weight-light;

            &:hover {
                font-weight: $font-weight-regular;
            }
        }
    }

    .access {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        gap: 32px;

        .ms-button.install {
            border-radius: 20px;
        }

        a:not(.ms-button) {
            @include font-style-link;

            font-weight: $font-weight-bold;
            text-decoration: none;
        }
    }

    &.white {
        background-color: $color-base-white;
        color: $color-base-gray;

        .ham .line {
            stroke: $color-base-gray;
        }
    }

    &.dark {
        background-color: $color-deep-green;
        color: $color-base-white;

        .ham .line {
            stroke: $color-base-gray;
        }
    }

    &.scrolled {
        background-color: $color-bg;
    }

    &.getting-started {
        min-height: 100dvh;

        a:not(.btn) {
            color: inherit;
            text-decoration: underline;
        }
    }
}

@media screen and (width <= $size-L) {
    header {
        gap: 12px;
    }
}

@media screen and (width <= $screen-width-logo-sm) {
    header h1 .logo svg {
        width: 140px;
    }
}

@media screen and (width <= $screen-width-menu-stretch) {
    header {
        nav {
            & > ul {
                gap: 0;
                
                li a {
                    padding: 0 12px;
                }
            }
        }

        .access {
            gap: 12px;
        }
    }
}

@media screen and (width <= $size-M) {
    header {
        flex-direction: column;
        gap: 0;
        padding: 0;

        .container {
            align-items: stretch;
        }

        h1 {
            align-self: stretch;
            height: $title-height;
            padding: 20px 30px;

            .logo {
                flex: 1 1 auto;

                svg {
                    height: 46px;
                    width: 168px;
                }
            }

            .ham {
                display: block;
            }
        }

        nav {
            display: none;

            .desktop-menu {
                display: none;
            }

            .mobile-menu {
                display: flex;
                overflow-y: auto;
                user-select: none;

                details {
                    @include font-style-body-intro;

                    padding: 0 16px;

                    &[open] {
                        summary > svg {
                            transform: rotate(180deg);
                        }
                    }

                    ::marker, ::-webkit-details-marker {
                        content: '';
                        display: none;
                    }

                    summary {
                        align-items: center;
                        display: flex;
                        font-weight: $font-weight-medium;
                        justify-content: space-between;
                        list-style: none;
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        font-size: 14px;
                        font-weight: $font-weight-light;
                        gap: 14px;
                        line-height: 150%;
                        margin: 14px 14px;

                        .feature {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }

                        .title {
                            align-items: center;
                            display: flex;
                            font-weight: $font-weight-bold;
                            gap: 5px;
                            text-transform: uppercase;
                        }

                        a {
                            text-decoration: none;

                            &::before {
                                content: '➜';
                                margin-right: 8px;
                            }

                            &:hover {
                                font-weight: $font-weight-bold;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                    }
                }
            }

            & > ul {
                align-items: stretch;
                flex-flow: column nowrap;
                justify-content: start;

                & > li {
                    flex: 1 1 100%;

                    &.submenu ul {
                        box-shadow: none;
                        display: flex;
                        left: auto;
                        padding: 12px 0;
                        position: relative;
                    }

                    & > a {
                        @include font-style-body-intro;

                        justify-content: space-between;
                    }
                }

                &:hover > li > a {
                    opacity: 1;
                }

            }

            &[aria-expanded='true'] {
                background-color: $color-base-white;
                color: $color-base-gray;
                display: inherit;
                flex-direction: column;
                justify-content: stretch;
                max-height: calc(100dvh - $title-height - $access-height);
                padding: 40px 12px 10px;
                width: 100%;

                & + .access {
                    background-color: $color-base-white;
                    display: flex;
                    gap: 30px;
                    height: $access-height;
                    justify-content: space-between;
                    padding: 40px 30px;

                    & > a {
                        flex: 1 1 50%;
                        text-align: center;
                    }

                    a:not(.ms-button) {
                        @include ms-button-secondary;

                        border-radius: 20px;
                    }
                }
            }
        }

        .access {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:72";