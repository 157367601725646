$color-gradient-accent: #312C91;
$color-bg: $color-deep-green;
$color-fg: $color-white;

footer {
    @include font-style-link;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer-sitemap {
        align-items: start;
        display: flex;
        flex-flow: row wrap;
        gap: 60px;
        justify-content: space-between;
        padding: 80px 40px;
        position: relative;

        .sitemap-blocks {
            display: flex;
            flex: 1 1 100%;
            flex-flow: row wrap;
            justify-content: space-between;

            & > div {
                display: flex;
                flex-direction: column;
                gap: 18px;
                min-width: 132px;

                h6 {
                    @include font-style-caption;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                }
            }
        }
    }     

    .footer {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 20px 40px;

        .container > div {
            display: flex;
            flex: 0 0 33%;
            flex-direction: row;
            gap: 8px;

            &.community {
                align-items: start;
                display: flex;
                flex-flow: row wrap;
                gap: 24px;
    
                p {
                    @include font-style-caption;
                }
    
                a {
                    line-height: 24px;
                }
    
                ul {
                    display: flex;
                    flex-flow: row wrap;
                    gap: 28px;
    
                    &.social {
                        gap: 12px;
    
                        a {
                            display: block;
                            line-height: 0;
                        }
                    }
                }
            }

            &.copy {
                gap: 20px;
                justify-content: center;
            }

            &.legal {
                justify-content: end;
            }
        }
    }

    &.white {
        background-color: $color-white;
        color: $color-darkish-grey;
    }

    @media (width <= $size-S) {
        .footer-sitemap .sitemap-blocks {
            display: grid;
            gap: 42px 24px;
            grid-template-columns: repeat(auto-fill, 200px);
        }

        .footer .container {
            gap: 24px;

            & > div {
                &.community,
                &.copy,
                &.legal {
                    flex: 0 0 100%;
                    justify-content: space-between;
                }

                &.community {
                    align-items: end;
                }
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:85";