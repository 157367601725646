@import './sizes';
@import './fonts';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

a {
    color: inherit;
}

input {
    font-family: inherit;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 128px;
}

body {
    @include font-style-body;

    background-color: $color-deep-green;
    color: $color-white;
}

header .container {
    gap: 8px;
}

.container {
    align-items: inherit;
    display: inherit;
    flex-direction: inherit;
    flex-wrap: inherit;
    gap: inherit;
    justify-content: inherit;
    margin: 0 auto;
    max-width: $size-container;
    width: 100%;
}

.hidden {
    display: none;
}

.only-mobile, .mobile {
    display: block;

    @media (width > $size-S) {
        display: none;
    }
}

.only-desktop, .desktop {
    display: none;

    @media (width > $size-M) {
        display: block;
    }
}

;@import "sass-embedded-legacy-load-done:43";