// Base
$color-base-gray: #333;
$color-base-green: #00B878;
$color-base-purple: #6964CE;
$color-base-white: #FFF;
$color-base-deep-navy: #010921;

// Secondary
$color-secondary-dark-purple: #544EC0;
$color-secondary-not-so-light-purple: #CDCBF0;
$color-secondary-light-purple: #ECECF9;
$color-secondary-blue: #008DF2;
$color-secondary-sky: #BFE4FE;
$color-secondary-yellow: #FFCB11;
$color-secondary-pink: #FF94D0;

// Neutral
$color-neutral-black: #000;
$color-neutral-light-gray: #F6F6F6;

// Not in styleguide
$color-ocean-blue: #002380;
$color-button-secondary: #2D2897;
$color-neutral-medium: #B8B8B8;

// New Logo Style
$color-vivid-green: #3DF2B6;
$color-deep-green: #1A2226;
$color-darkish-grey: #22282B;
$color-dark-grey: #464c4b;
$color-mid-grey: #abb1b1;
$color-light-grey: #ddd;
$color-almost-white: #F0F7F7;
$color-white: #fff;
$color-light-peach: #ef9684;
$color-tech-purple: #8593f7;
$color-tech-blue: #548DFF;
$color-bright-amber: #f4bf61;
;@import "sass-embedded-legacy-load-done:1";